<template>
  <div class="setting" v-if="!isProcessing">
    <div class="setting__menu" v-for="item in items" :key="item.title">
      <setting-menu :item="item" />
    </div>
  </div>
</template>

<script>
import SettingMenu from '@/components/common/menu.vue'

export default {
  name: 'setting',
  components: { SettingMenu },
  data () {
    return {
      items: [
        { title: 'ログアウト', content: 'ログアウトの実行', func: () => this.signout() }
      ]
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * サインアウト
     */
    signout () {
      this.$store.commit('setSubmitting', true)
      this.$store.dispatch('auth/signout').then(result => {
        // サインアウト成功時はログイン画面へ、失敗場合はエラー画面へ
        result.status === 'success' ? this.$router.push({ name: 'login' }) : this.$router.push({ name: 'error' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.setting {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
}
</style>
